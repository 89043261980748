<!--
 * @Author: jiang
 * @Date: 2021-08-03 17:17:18
 * @Description: 职务列表
-->
<template>
  <el-select
    :value="value"
    placeholder="请选择职务"
    @change="onChange"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { getPostList } from '@/api/admin/post'

export default {
  props: {
    value: {},
  },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      getPostList().then(res => {
        this.list = res
      })
    },
    onChange(val) {
      this.$emit('input', val)
    },
  },
}
</script>
