<!--
 * @Author: jiang
 * @Date: 2021-07-02 09:55:06
 * @Description:
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <el-form-item
      label="用户姓名"
      prop="name"
    >
      <el-input
        v-model="formData.name"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item
      label="手机号"
      prop="phone"
    >
      <el-input
        v-model="formData.phone"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item
      label="所在部门"
      prop="department_id"
    >
      <el-cascader
        style="display: block;"
        v-model="formData.department_id"
        placeholder="请选择"
        :options="depTree"
        :props="{ checkStrictly: true, value: 'id', label: 'name', emitPath: false }"
        filterable
      ></el-cascader>
    </el-form-item>
    <el-form-item
      label="职务"
      prop="duties_id"
    >
      <b-post-select v-model="formData.duties_id"></b-post-select>
    </el-form-item>
    <el-form-item
      label="密码"
      prop="password"
    >
      <el-input
        v-model="formData.password"
        type="password"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item
      label="确认密码"
      prop="password_confirmation"
    >
      <el-input
        v-model="formData.password_confirmation"
        type="password"
        placeholder="请输入"
        clearable
      ></el-input>
    </el-form-item>
    <div class="g-center">
      <el-button
        type="primary"
        @click="onSubmit"
        :loading="loading"
      >保存</el-button>
    </div>
  </el-form>
</template>

<script>
import BPostSelect from '@/components/select/post-select'
import { getDeptTree } from '@/api/user'
export default {
  components: {
    BPostSelect,
  },
  props: {
    data: {},
    loading: {},
  },
  data() {
    return {
      depTree: [],
      formData: {},
      formRules: {
        name: [{ required: true, message: '必填项', trigger: 'blur' }],
        phone: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: this.$validator.isPhone },
        ],
        department_id: [{ required: true, message: '必填项', trigger: 'change' }],
        password: [],
        password_confirmation: [],
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        const passwordValid = (rule, value, callback) => {
          if (
            this.formData.password &&
            this.formData.password_confirmation &&
            this.formData.password !== this.formData.password_confirmation
          ) {
            callback(new Error('俩次输入密码不相同'))
          } else {
            callback()
          }
        }
        this.formData = val
        if (val.id) {
          this.formRules.password = []
          this.formRules.password_confirmation = []
        } else {
          this.formRules.password = [
            { required: true, message: '必填项', trigger: 'blur' },
            { validator: passwordValid },
          ]
          this.formRules.password_confirmation = [
            { required: true, message: '必填项', trigger: 'blur' },
            { validator: passwordValid },
          ]
        }
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fetchDepList()
  },
  methods: {
    fetchDepList() {
      getDeptTree().then(res => {
        this.depTree = res.children
      })
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submit', this.formData)
        }
      })
    },
  },
}
</script>
